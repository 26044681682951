

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300..900&display=swap');
:root{
    --grey-100:"#f1f1f1f",
    --grey-200: "#fafafa",
     --grey-1700:"1#71717"
     --blue-primary:"#2866df"
}




  
    .card2{
      position: relative;
      width: 100%;
      min-height: 686px;
    
      
      background-color: #fff;
      border-radius: 15px;
box-shadow:  0,0,50px, rgba(23, 23, 23, 0.2);
z-index: 100;
border: 1px solid #f6f6f6;
letter-spacing: 0.3px;

      
    }

    .card2 .header{
        position: relative;
        width: 100%;
        height: 100px;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
    }
.card2 .header p{
    position: relative;
    top: 10px;
    color: #4f46e5;
    font-weight: 600px;
    font-weight: bold;
 
    font-size: 15px;
    font-family: "Inter", sans-serif;
}

.card2 .header div{
    font-size: 35px;
    font-family: "Inter", sans-serif;
    color: #171717;
    font-weight: bolder;
}
.card2 .card_patelets{
    position: relative;
    width: 100%;
 height: 80%;
display: flex;
flex-wrap: wrap;
 margin-top: 30px;
 display: flex;
 gap: 10px;
  justify-content: space-evenly;

}

.card2 .card_patelets .card{
 position: relative;
  padding: 10px 0px 0px 30px;
 width: 320px;
 min-height: 510px;
 
 border:  1px solid #e5e7eb;
 border-radius: 10px;
  background-color: #fff;
  box-shadow: 0, 1px, 0 , rgba(236, 236, 236, 0.5);
font-family: "Inter", sans-serif ;
display: flex;
flex-direction: column;
gap: 10px;
 
}

.card2 .card_patelets .card .card_header .basic{
    position: relative;
    width: 100%;
    /* height: 50px; */
      font-weight: bolder;
      color: #4b5563;
      font-size: 26px;
}

.card2 .card_patelets .card .card_header .meta{
    width: 80%;
     color: #4b5563;
}

.card2 .card_patelets .card .card_header .price{
    position: relative;
    width: 100%;
 
  
    
}
.card2 .card_patelets .card  .price .dollor{
    position: relative;
    left: 2px;
     font-size: 29px;
     font-weight: bolder;
     color: #4b5563;
     
     
}
.card2 .card_patelets .card  .price .month {
    position: relative;

    color: #4b5563;
  
    font-weight: bold;

}

.card2 .card_patelets  .card .button{
    position: relative;
    margin-top: 20px;
    
    width: 100%;
    
   
    display: flex;
    justify-items: center;
    align-items: center;
  cursor: pointer;


}
.card2 .card_patelets  .card .button div{
    position: relative;
    width: 90%;
    height: 40px;
    border-radius: 8px;
    background-color: #fafafa;
    border: 1px solid #ececec;
    box-shadow: 0, 1px 0, rgba(236, 236, 236, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  color: #2866df;
  font-weight: bold;

}



.card2 .card_patelets .card .advantages{
    position: relative;
    margin-top: 40px;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.card2 .card_patelets .card .advantages .child{
    position: relative;
    width: 100%;
    display: flex;
    gap: 5px;
}

.card2 .card_patelets .card .advantages .child .bx{
     position: relative;
     color: #2866df;
}

.card2 .card_patelets .card .advantages .child span{
    position: relative;
    color: #4b5563;
}
