
.reactflow .react-flow__handle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: black;
  }
#active{
  background-color: #edeef1;
  border-radius: 5px;
  padding: 5px;
  width: 30px;
  text-decoration: none;
}

.simple-floatingedges {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  
  .simple-floatingedges .react-flow__handle {
    width: 8px;
    height: 8px;
    background-color: #bbb;
  }
  
  .simple-floatingedges .react-flow__handle-top {
    top: -15px;
  }
  
  .simple-floatingedges .react-flow__handle-bottom {
    bottom: -15px;
  }
  
  .simple-floatingedges .react-flow__handle-left {
    left: -15px;
  }
  
  .simple-floatingedges .react-flow__handle-right {
    right: -15px;
  }
  
  .simple-floatingedges .react-flow__node-custom {
    background: #fff;
    border: 1px solid #1a192b;
    border-radius: 3px;
    color: #222;
    font-size: 12px;
    padding: 10px;
    text-align: center;
    width: 150px;
  }
  
 